import React from "react"
import { orgName } from "../../constants"

export default function Form({ formName = "general" }) {
  return (
    <form
      name={formName}
      className="contact-form"
      data-netlify="true"
      netlify-honeypot="bot-field"
      method="post"
    >
      <input type="hidden" name="form-name" value={formName} />
      <input className="hidden" name="bot-field" />
      <span className="field-name">Full Name</span>
      <input
        className="contact-input"
        placeholder="Kevin Nguyen"
        type="text"
        name="name"
        required
      />
      <span className="field-name">Email Address</span>
      <input
        className="contact-input"
        name="email"
        type="email"
        placeholder="kevin@email.com"
        required
      />
      <span className="field-name">Message</span>
      <textarea
        placeholder={`Hello ${orgName}.`}
        className="contact-message"
        name="message"
        required
      />
      <button type="submit" className="action-button contact-button">
        Send Message
      </button>
    </form>
  )
}
