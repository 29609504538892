import React from "react"
import { orgAcronym } from "../../constants"
import { getNewsletter } from "../../utils"

export default function AUP({
  org,
  orgLink,
  text,
  header,
  desc1,
  desc2,
  getInvolved,
  learnMore,
  brandColor,
  img,
}) {
  return (
    <div className="aup-content">
      <div className="aup-image" style={{ backgroundImage: `url(${img})` }}>
        <div className="aup-text">
          <div>{text}</div>
          <h3 className="header">{header}</h3>
          <div className="text">{desc1}</div>
          <hr />
          <div className="text">{desc2}</div>
          <div className="btn-container">
            <a href={getInvolved} rel="noopener" target="_blank">
              <button className="action-button primary-button">
                Get Involved
              </button>
            </a>

            <a href={learnMore} rel="noopener" target="_blank">
              <button className="action-button secondary-button">
                Learn More
              </button>
            </a>
          </div>
        </div>
        <div className="collab-text">
          <a
            className="org-link"
            href={"https://www.facebook.com/groups/subtle.asian.networking"}
            rel="noopener"
            target="_blank"
          >
            {orgAcronym}
          </a>{" "}
          ×{" "}
          <a
            href={orgLink}
            rel="noopener"
            target="_blank"
            style={{ color: brandColor }}
          >
            {org}
          </a>
        </div>
      </div>
    </div>
  )
}
