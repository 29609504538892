import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AUP from "./AUP"
import Form from "../Contact/Form"
import { partnerships } from "../../constants"

export default function Partners() {
  const data = useStaticQuery(graphql`
    query {
      AASPN: file(relativePath: { eq: "AASPN.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HiRN: file(relativePath: { eq: "hirightnow-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      myp: file(relativePath: { eq: "myp.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="partners-container">
      <h4>Make an impact</h4>
      <h3>
        Partner with us<span className="period">.</span>
      </h3>
      <div className="partners">
        <div className="left">
          <Form formName="partners" />
        </div>
        <div className="right">
          <div className="partners-subtitle">
            Some of the partners we’re proud to work with
            <span className="period">.</span>
          </div>
          <div className="partner-container">
            <a
              href={"https://www.hirightnow.co/"}
              rel="noopener"
              target="_blank"
            >
              <Img
                className="partners-logo"
                fluid={data.HiRN.childImageSharp.fluid}
                alt="Hi Right Now logo"
              />
            </a>
            <a
              href={"https://meetyourpearl.com/"}
              rel="noopener"
              target="_blank"
            >
              <Img
                className="partners-logo"
                fluid={data.myp.childImageSharp.fluid}
                alt="Meet Your Pearl logo"
              />
            </a>
            <a href={"https://aaspn.com"} rel="noopener" target="_blank">
              <Img
                className="partners-logo"
                fluid={data.AASPN.childImageSharp.fluid}
                alt="AASPN logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="partners-subtitle margin-top">
        Projects that made a difference<span className="period">.</span>
      </div>
      {partnerships.map((partner, i) => (
        <AUP
          org={partner.org}
          orgLink={partner.orgLink}
          text={partner.text}
          header={partner.header}
          desc2={partner.desc2}
          desc1={partner.desc1}
          getInvolved={partner.getInvolved}
          learnMore={partner.learnMore}
          brandColor={partner.brandColor}
          img={partner.img}
        />
      ))}
    </div>
  )
}
