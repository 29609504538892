import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Partners from "../components/Partnerships/Partners"

export default function PartnersPage() {
  return (
    <Layout>
      <SEO title="Partners" />
      <Partners />
    </Layout>
  )
}
